import * as React from 'react';
import { SVGProps } from 'react';

function ToastSuccessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 40 40" {...props}>
      <g transform="translate(-40 -30)">
        <circle cx={20} cy={20} r={20} transform="translate(40 30)" fill="#0db18d" />
        <path
          d="M58.712 56.012a2.1 2.1 0 01-1.372-.506l-4.646-4a1.958 1.958 0 01-.172-2.825 2.109 2.109 0 012.915-.166l3.091 2.663 5.857-6.488a2.112 2.112 0 012.914-.189 1.959 1.959 0 01.195 2.824l-7.228 8.006a2.09 2.09 0 01-1.425.68c-.043-.004-.086.001-.129.001z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default ToastSuccessIcon;
