import React, { useEffect, useContext } from 'react';
import CreditPackages from '../CreditPackages/CreditPackages';
import ProductsService from '../Payment/ProductsService';
import { CreditProps } from '../CreditInterfaces/CreditInterfaces';
import CreditsLeft from '../CreditsLeft/CreditsLeft';
import useBuyCredits from '../../../hooks/useBuyCredits/useBuyCredits';
import ModalPopUp from '../../ModalPopUp/ModalPopUp';
import { CloseButton } from '../../Buttons/CloseButton';
import { ConfirmButton } from '../../Buttons/ConfirmButton';
import { NotificationsToastsService } from '../../../services/notifications/NotificationsToastService';
import CreditsContext from '../CreditsContext/CreditsContext';
import styles from './BuyCredits.module.css';

const BuyCredits = () => {
  const { setSelectedCredit, onBuyAction, disabled, loading, isSuccess, setIsSuccess } = useBuyCredits();
  const { success, error } = NotificationsToastsService();
  const { walletBalance, closeModal, displayCreditsModal, hasEnoughCredits } = useContext(CreditsContext);

  const clearSelected = () => {
    ProductsService()
      .findAll()
      .forEach(credit => (credit.selected = false));
  };

  const onSelect = (credit: CreditProps) => {
    setSelectedCredit(credit);
  };

  const setCustomCredit = (value: number) => {
    setSelectedCredit(value);
  };

  const successfullCreditRefill = () => {
    // TODO - change Successfull message
    success({ message: 'Successfull refill' });
    setIsSuccess(undefined);
    closeModal();
  };

  const creditRefillError = () => {
    // TODO - change error message
    error({ message: 'Something went wrong, try again through application' });
    setIsSuccess(undefined);
    closeModal();
  };

  const onCloseAction = () => {
    !hasEnoughCredits &&
      NotificationsToastsService().warning({ message: 'You will run out of money soon! Please, fill your wallet!' });
    closeModal();
  };

  useEffect(() => {
    if (isSuccess !== undefined) {
      isSuccess ? successfullCreditRefill() : creditRefillError();
    }
  }, [isSuccess]);

  if (!displayCreditsModal) {
    return null;
  }

  return (
    <ModalPopUp closeAction={onCloseAction}>
      <CreditsLeft creditsAmount={walletBalance} />

      {/*TODO - possibile message props will not be needed in CreditPackages and shpuld be moved to other place*/}
      <CreditPackages
        products={ProductsService().findAll()}
        clearSelected={clearSelected}
        onSelect={onSelect}
        setCustomCredit={setCustomCredit}
        message="Not enough money! Refill."
      />

      <div className={styles.controls}>
        <ConfirmButton buttonText="Add money to your wallet" onSubmit={onBuyAction} isDisabled={disabled || loading} />
        <CloseButton buttonText="Cancel" onClose={onCloseAction} />
      </div>
    </ModalPopUp>
  );
};

export default BuyCredits;
