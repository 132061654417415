import { useState } from 'react';
import { TIPS_URL, MESSAGES_URL } from '../../services/APIConsts';
import { NotificationsToastsService } from '../../services/notifications/NotificationsToastService';
import useFetch from '../useFetch/useFetch';
import { useLocation } from 'react-router-dom';

const TIP_TYPE = 'TIP';
const TIP_TYPE_ID = 4;

export const useTips = (senderId: string | undefined, receiverId: string | undefined) => {
  const [tipAmount, setTipAmount] = useState<number>(0);
  const [tipMessage, setTipMessage] = useState<string>('');

  const search = useLocation().search;
  const authToken = new URLSearchParams(search).get('auth_token');
  const isTipUnset = tipAmount === 0;
  const hasMessage = tipMessage !== '';
  const isOnlyTip = !hasMessage;

  const tipParams = isOnlyTip
    ? {
        url: TIPS_URL,
        params: {
          amount: tipAmount,
          receiver_id: receiverId,
          sender_id: senderId,
        },
      }
    : {
        url: MESSAGES_URL,
        params: {
          amount: tipAmount,
          ...(tipMessage ? { text: tipMessage } : {}),
          type_id: TIP_TYPE_ID,
          type: TIP_TYPE,
          receiver_id: receiverId,
          sender_id: senderId,
          created: Date.now() / 1000,
        },
      };

  const { post: tipSend } = useFetch(tipParams.url, { authToken });

  const sendTip = async () => {
    const { isSuccess } = await tipSend(tipParams.params);

    if (isSuccess) {
      NotificationsToastsService().success({
        message: 'Tip successfully sent',
      });
    }

    if (!isSuccess) {
      NotificationsToastsService().error({
        message: 'Something went wrong or you do not have enough money to tip',
      });
    }
  };

  return {
    sendTip,
    setTipAmount,
    setTipMessage,
    isTipUnset,
    tip: tipParams.params,
  };
};
