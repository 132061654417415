import React from 'react';

function WhiteFlipCameraIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="30" viewBox="0 0 70.092 70.092">
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        data-name="Icon feather-refresh-cw"
        transform="rotate(45 15.954 41.73)"
      >
        <path d="M47.956 6.61v12.67h-12.67" data-name="Path 651"></path>
        <path d="M1.5 40.397v-12.67h12.67" data-name="Path 652"></path>
        <path
          d="M6.8 17.168a19 19 0 0131.358-7.1l9.8 9.207M1.5 27.727l9.8 9.207a19 19 0 0031.358-7.1"
          data-name="Path 653"
        ></path>
      </g>
    </svg>
  );
}

export default WhiteFlipCameraIcon;
