import React, { createContext, useEffect, useCallback } from 'react';
import Config from '../../../config';
import { useClientsCallState } from '../../ClientsCalls/useClientsCall/ClientsCallsContext';
import { NotificationsToastsService } from '../../../services/notifications/NotificationsToastService';

const socketIOClient = require('socket.io-client');

type NotificationsContextType = {};

export const NotificationsContext = createContext<NotificationsContextType>(null!);

export const NotificationsProvider: React.FC = ({ children }) => {
  const { clientsCall } = useClientsCallState();

  const getAuthData = useCallback(() => {
    const client = clientsCall?.client;
    const clientId = client ? client.clientId : null;
    return {
      type: 'AUTH',
      payload: {
        client_id: clientId,
      },
    };
  }, [clientsCall?.client]);

  const onSocketEvent = (data: any) => {
    const notification = JSON.parse(data);

    if (notification.type === 'TIPS' && clientsCall?.corespondentClient.clientId === notification.payload.sender_id) {
      NotificationsToastsService().success({
        message: `${clientsCall?.corespondentClient.username} sent you a €${notification.payload.amount} tip.`,
      });
    }
  };

  useEffect(() => {
    const client = clientsCall?.client;
    if (client) {
      const socket = socketIOClient(Config.SOCKET_URL, {
        query: { auth: JSON.stringify(getAuthData()) },
      });
      socket.on(`${Config.SOCKET_CHANEL}|${client.clientId}`, onSocketEvent);
    }
  }, []);

  return <NotificationsContext.Provider value={{}}>{children}</NotificationsContext.Provider>;
};
