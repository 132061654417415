import React from 'react';

function WhiteVideoOnIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 65.714 44">
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        data-name="Icon feather-video"
        transform="translate(1.5 -4.5)"
      >
        <path d="M61.214 12.929l-19 13.571 19 13.572z" data-name="Path 658"></path>
        <path
          d="M6.929 7.5h29.857a5.429 5.429 0 015.429 5.429v27.142a5.429 5.429 0 01-5.429 5.429H6.929A5.429 5.429 0 011.5 40.071V12.929A5.429 5.429 0 016.929 7.5z"
          data-name="Path 659"
        ></path>
      </g>
    </svg>
  );
}

export default WhiteVideoOnIcon;
