import React from 'react';
import DefaultCurrency from '../../../helpers/DefaultCurrency';
import { ReactComponent as CoinSingle } from './../../../svg/coin_single.svg';
import styles from './CreditsLeft.module.css';

interface CreditsLeftProps {
  creditsAmount: number | undefined;
}

const CreditsLeft = ({ creditsAmount }: CreditsLeftProps) => {
  return (
    <div className={styles.credits_left_wrapper}>
      <div className={styles.total_credits}>
        <CoinSingle className={styles.large_coin} />
        {
          <span className={styles.credits_left}>
            {creditsAmount} <DefaultCurrency />
          </span>
        }
      </div>
    </div>
  );
};

export default CreditsLeft;
