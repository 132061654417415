import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { IClient } from '../../ClientsCalls/useClientsCall/Client';
import Config from '../../../config';
import Media from '../../Media/Media';
import avatar from '../../../images/avatar.png';
import CorespondentIdentity from '../CorespondentIdentity/CorespondentIdentity';

const useStyles = makeStyles((theme: Theme) => ({
  corespondentInfo: {},
  corespondentAvatar: {
    display: 'block',
    width: '90px',
    height: '90px',
    borderRadius: '41.6667%',
    overflow: 'hidden',
    margin: '0 auto 15px',
    objectFit: 'cover',
  },
}));

interface IProps {
  corespondentClient: IClient;
}

const CorespondentClientInfo = ({ corespondentClient }: IProps) => {
  const classes = useStyles();

  const mediaUrl = Media(corespondentClient.media).isValid()
    ? `${Config.STATIC_URL}/${Media(corespondentClient.media).getAvatar()}`
    : avatar;

  return (
    <div className={classes.corespondentInfo}>
      <img className={classes.corespondentAvatar} src={mediaUrl} />
      <CorespondentIdentity corespondentClient={corespondentClient} />
    </div>
  );
};

export default CorespondentClientInfo;
