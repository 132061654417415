import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch/useFetch';
import { CallStatusConst } from './ClientsCallsConstants';
import { IClient } from './Client';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { isInviter } from '../../../helpers/clientHelpers';
import { Room } from 'twilio-video';

export interface IClientsCall {
  callId: string;
  client: IClient;
  corespondentClient: IClient;
  callPrice: number;
  inviterClientId: string;
  responderClientId: string;
}

export const ClientsCallContext = createContext<IClientsCallContext>(null!);

interface ClientsCallProps {
  callId: string;
  authToken: string;
  children: ReactNode;
}

const TIME_INTERVAL = 30000;

const isBothInTheRoom = (room: Room | null) => room?.participants instanceof Map && room.participants.size > 0;

export default function ClientsCallContextProvider({ callId, authToken, children }: ClientsCallProps) {
  const [callStatus, setCallStatus] = useState(CallStatusConst.CALLING);
  const [clientsCall, setClientsCall] = useState<IClientsCall | null>(null);
  const { room } = useVideoContext();
  const { CALL_STARTED, CALL_FINISHED } = CallStatusConst;
  const { get: fetchClientsCall } = useFetch(`/clients-calls?call_id=${callId}`, { authToken });
  const { post: chargeClientCall } = useFetch(`/charge-client-call`, { authToken });
  const { put: updateClientsCallStatus } = useFetch(`/clients-calls-status`, { authToken });
  const { post: callInvite } = useFetch(`/call-invite`, { authToken });

  const env = process.env;

  useEffect(() => {
    getClientCall();
  }, []);

  useEffect(() => {
    if (room) {
      isBothInTheRoom(room) && setCallStatus(CALL_STARTED);
      room.on('participantConnected', setStartedCallStatus);
      room.on('participantDisconnected', callDisconnected);
      room.on('disconnected', callDisconnected);
    }
  }, [room]);

  // Charge money every 30sec
  useEffect(() => {
    if (callStatus === CALL_FINISHED) {
      window.location.href = `${env.REACT_APP_WEBSITE_URL}/messages/${clientsCall?.corespondentClient.clientId}`;
    }
    if (callStatus === CALL_STARTED) {
      chargeClientCall({ callId });
      const interval = setInterval(async () => {
        const response = await chargeClientCall({ callId });
        console.log(response.code);
        if (response.code === 400) {
          console.log('callDisconnected');
          callDisconnected();
        }
      }, TIME_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [callStatus]);

  const getClientCall = async () => {
    const response = await fetchClientsCall();
    setClientsCall(response.response);
  };

  const sendCallInvite = async () => {
    await callInvite({ callId });
  };

  const setStartedCallStatus = async () => {
    await updateClientsCallStatus({ callId, callStatusId: CALL_STARTED });
    setCallStatus(CALL_STARTED);
  };

  const callDisconnected = async () => {
    await updateClientsCallStatus({ callId, callStatusId: CALL_FINISHED });
    setCallStatus(CALL_FINISHED);
    // TODO - return to app ???
  };

  let contextValue = {
    clientsCall,
    authToken,
    sendCallInvite,
    isInviter: clientsCall && isInviter(clientsCall),
    callStatus,
  } as IClientsCallContext;

  if (!clientsCall) {
    return <></>;
  }

  return <ClientsCallContext.Provider value={{ ...contextValue }}>{children}</ClientsCallContext.Provider>;
}

export interface IClientsCallContext {
  clientsCall: IClientsCall | null;
  authToken: string;
  sendCallInvite: () => {};
  isInviter: boolean;

  callStatus: number;
}

export function useClientsCallState() {
  const context = useContext(ClientsCallContext);
  if (!context) {
    throw new Error('ClientsCallContext must be used within the ClientsCallContextProvider');
  }
  return context;
}
