import { useState, useEffect } from 'react';
import { CreditProps } from '../../components/CreditsFill/CreditInterfaces/CreditInterfaces';
import ProductsService from '../../components/CreditsFill/Payment/ProductsService';
import PaymentService from '../../components/CreditsFill/Payment/PaymentService';
import useCreditsPurchase from './useCreditsPurchase';

const useBuyCredits = () => {
  const [selectedCredit, setSelectedCredit] = useState<CreditProps | number | undefined>(
    ProductsService().findSelected()
  );
  const { setPurchaseAmount, loading, isSuccess, setIsSuccess } = useCreditsPurchase(undefined);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    selectedCredit && checkPriceValidity();
  }, [selectedCredit]);

  const getPrice = () => {
    return typeof selectedCredit === 'object' ? selectedCredit.price : selectedCredit;
  };

  const onBuyAction = () => {
    const amount = getPrice();
    if (amount) {
      setPurchaseAmount(amount);
    }
  };

  const checkPriceValidity = () => {
    const amount = getPrice();

    if (amount) {
      setDisabled(Boolean(!PaymentService().isValid(amount)));
    }
  };

  return { setSelectedCredit, onBuyAction, disabled, loading, isSuccess, setIsSuccess };
};

export default useBuyCredits;
