import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import DefaultCurrency from '../../../helpers/DefaultCurrency';
import { useTips } from '../../../hooks/useTips/useTips';
import { ConfirmButton } from '../../Buttons/ConfirmButton';
import { CloseButton } from '../../Buttons/CloseButton';
import { useTranslation } from 'react-i18next';
import CreditsContext from '../../CreditsFill/CreditsContext/CreditsContext';

const useStyles = makeStyles((theme: Theme) => ({
  customInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    height: '55px',
    backgroundColor: theme.colors.searchFormBg,
    paddingLeft: '15px',
    paddingRight: '15px',
    marginBottom: '8px',
  },
  inputWrapper: {
    marginLeft: '10px',
    border: 'none',
    color: theme.colors.darkGrey,
    background: 'transparent',
    fontSize: '18px',
    width: '100%',

    '&::placeholder': {
      fontStyle: 'italic',
    },

    '&:-ms-input-placeholder': {
      /* Internet Explorer 10-11 */
      fontStyle: 'italic',
    },

    '&::-ms-input-placeholder': {
      /* Microsoft Edge */
      fontStyle: 'italic',
    },
  },
  tipButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    width: '100%',
    marginTop: '50px',
  },
}));

interface Props {
  closeAction: (arg: boolean) => void;
  inviterClientId: string | undefined;
  responderClientId: string | undefined;
  authToken: string;
}

const TipForm = ({ closeAction, inviterClientId, responderClientId, authToken }: Props) => {
  const NO_TIP_AMOUNT = 0;
  const { sendTip, setTipAmount, setTipMessage, isTipUnset, tip } = useTips(inviterClientId, responderClientId);
  const { walletBalance, setForceBuyCredits } = useContext(CreditsContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const setAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setTipAmount(NO_TIP_AMOUNT);
    }
    if (parseInt(event.target.value) > NO_TIP_AMOUNT) {
      const value = parseInt(event.target.value);
      setTipAmount(value);
    }
  };

  const forbidDecimals = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  const setMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      setTipMessage(event.target.value);
    }
  };

  const sendTipAction = () => {
    if (tip.amount > walletBalance!) {
      setForceBuyCredits(true);
      closeAction(false);
      return;
    }

    sendTip();
    closeAction(false);
  };

  return (
    <form className="tip_form">
      <div className={classes.customInputWrapper}>
        <span>
          <DefaultCurrency />
        </span>
        <input
          type="number"
          id="tip_amount"
          name="tip_amount"
          min="1"
          onChange={setAmount}
          onKeyDown={forbidDecimals}
          placeholder={t('amount')}
          className={classes.inputWrapper}
        />
      </div>

      <div className={classes.customInputWrapper}>
        <input
          type="text"
          id="tip_message"
          name="tip_message"
          className={classes.inputWrapper}
          placeholder={t('type a message')}
          onChange={setMessage}
          maxLength={1000}
        />
      </div>

      <div className={classes.tipButtonsWrapper}>
        <ConfirmButton buttonText={t('send a tip')} onSubmit={sendTipAction} isDisabled={isTipUnset} />
        <CloseButton buttonText={t('cancel')} onClose={closeAction} />
      </div>
    </form>
  );
};

export default TipForm;
