import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import WhiteDisconnectIcon from '../../../icons/WhiteDisconnectIcon';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  mobileButtonBg: {
    display: 'flex',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '40%',
    background: 'rgba(0, 0, 0, 0.4)',
    marginLeft: '5px',
    marginRight: '5px',
  },
  startIcon: {
    marginLeft: '0',
    marginRight: '0',
  },
}));

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const handleClick = () => {
    room!.disconnect();
  };

  return (
    <div className={classes.mobileButtonBg}>
      <Button
        className={props.className}
        classes={{ startIcon: classes.startIcon }}
        onClick={handleClick}
        startIcon={<WhiteDisconnectIcon />}
        data-cy-disconnect
      ></Button>
    </div>
  );
}
