import React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/styles';

const CustomButton = styled(Button)({
  borderRadius: '25px',
  backgroundColor: '#e5e5e5',
  color: '#000',
  height: '50px',
  fontSize: '20px',
});

interface Props {
  buttonText: string;
  onClose: (arg: boolean) => void;
}

export const CloseButton = ({ buttonText, onClose }: Props) => {
  return (
    <CustomButton fullWidth={true} onClick={() => onClose(false)} type="button">
      {buttonText}
    </CustomButton>
  );
};
