import React, { useState, useEffect } from 'react';
import './NotificationToast.scss';
import { NotificationsToastsService } from '../../../services/notifications/NotificationsToastService';
import NotificationAlert from '../NotificationAlert/NotificationAlert';

const DESTROY_AFTER = 3000;
export const SUCCESS_STATUS = 'success';
export const ERROR_STATUS = 'error';
export const WARNING_STATUS = 'warning';

export interface NotificationType {
  status: string;
  message: string;
}

function NotificationsToast() {
  const [notification, setNotification] = useState<NotificationType | undefined>(undefined);

  useEffect(() => {
    NotificationsToastsService().on(add);
  }, []);

  const add = (not: NotificationType) => {
    setNotification(not);
    setTimeout(() => {
      clearNotification();
    }, DESTROY_AFTER);
  };

  const clearNotification = () => {
    setNotification(undefined);
  };

  if (!notification) {
    return <></>;
  }

  return (
    <div className="notification_toast">
      <NotificationAlert notification={notification} />
    </div>
  );
}

export default NotificationsToast;
