import { useEffect, useState } from 'react';
import useFetch from '../useFetch/useFetch';
import { CREDITS_URL } from '../../services/APIConsts';
import { useClientsCallState } from '../../components/ClientsCalls/useClientsCall/ClientsCallsContext';
import WalletHelper from '../../components/CreditsFill/Payment/WalletHelper';
import { isFollower } from '../../helpers/clientHelpers';

const useWalletStatus = () => {
  const { clientsCall, authToken } = useClientsCallState();
  const [walletBalance, setWalletBalance] = useState<number>();
  const [hasEnoughCredits, setHasEnoughCredits] = useState<boolean>();
  const [shortToContinue, setShortToContinue] = useState<boolean>();

  const { get: getWalletStatus } = useFetch(`${CREDITS_URL}?correspondent_id=${clientsCall?.responderClientId}`, {
    authToken,
  });

  useEffect(() => {
    if (clientsCall) {
      isFollower(clientsCall.client.clientType) && getCredits();
    }
  }, []);

  const getCredits = async () => {
    try {
      const response = await getWalletStatus();

      if (response.isSuccess) {
        let floatBalance = WalletHelper().balanceToFloat(response.response.balance);
        let floatCallPrice = WalletHelper().balanceToFloat(response.response.callPrice);
        let callCreditsLeft = WalletHelper().checkCallCreditStatus(floatBalance, floatCallPrice);
        let isNotSolvent = WalletHelper().hasLessThanCallPrice(floatBalance, floatCallPrice);

        setWalletBalance(floatBalance);
        setHasEnoughCredits(callCreditsLeft);
        setShortToContinue(isNotSolvent);
      } else {
        // TODO - steps if something went wrong
        console.log('something went wrong');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { walletBalance, hasEnoughCredits, getCredits, shortToContinue };
};

export default useWalletStatus;
