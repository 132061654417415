import React, { useEffect, useState } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import MediaErrorSnackbar from './components/PreJoinScreens/MediaErrorSnackbar/MediaErrorSnackbar';
import Room from './components/Room/Room';
import DeviceSelectionScreen from './components/PreJoinScreens/DeviceSelectionScreen/DeviceSelectionScreen';
import { useLocation } from 'react-router-dom';
import DirectCall from './components/PreJoinScreens/DeviceSelectionScreen/DirectCall';
import NotificationsToast from './components/Notifications/NotificationToast/NotificationsToast';
import './i18n.ts';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  background: 'black',
}));

export default function App() {
  const { getAudioAndVideoTracks } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();
  const roomState = useRoomState();
  const height = useHeight();
  const search = useLocation().search;
  const isDirectCall = new URLSearchParams(search).get('direct-call');

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  return (
    <Container style={{ height }}>
      <NotificationsToast />
      {roomState === 'disconnected' ? (
        <div>
          <MediaErrorSnackbar error={mediaError} />
          {isDirectCall ? <DirectCall /> : <DeviceSelectionScreen />}
        </div>
      ) : (
        <Main>
          <Room />
        </Main>
      )}
    </Container>
  );
}
