import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { IClient } from '../../ClientsCalls/useClientsCall/Client';
import VerifiedBadgeIcon from '../../../icons/VerifiedBadgeIcon';

const useStyles = makeStyles((theme: Theme) => ({
  corespondentIdentity: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

interface IProps {
  corespondentClient: IClient;
}

const CorespondentIdentity = ({ corespondentClient }: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.corespondentIdentity}>
      <span className={'corespondentUsername'}>{corespondentClient.username}</span>
      <span className={'corespondentVerified'}>{corespondentClient.verified && <VerifiedBadgeIcon />}</span>
    </div>
  );
};

export default CorespondentIdentity;
