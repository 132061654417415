import { useEffect, useState } from 'react';
import { NotificationsToastsService } from '../../services/notifications/NotificationsToastService';
import useVideoContext from '../useVideoContext/useVideoContext';
import useWalletStatus from '../useWalletStatus/useWalletStatus';
import WalletHelper from '../../components/CreditsFill/Payment/WalletHelper';
import { useClientsCallState } from '../../components/ClientsCalls/useClientsCall/ClientsCallsContext';
import { isFollower } from '../../helpers/clientHelpers';

const useCreditsHandler = () => {
  const { room } = useVideoContext();
  const { clientsCall, callStatus } = useClientsCallState();
  const { walletBalance, hasEnoughCredits, getCredits, shortToContinue } = useWalletStatus();
  const [showModal, setShowModal] = useState<boolean | undefined>();
  const [forceBuyCredits, setForceBuyCredits] = useState<boolean>(false);

  useEffect(() => {
    clientsCall && isFollower(clientsCall.client.clientType) && callStatus === 2 && heartbeatCredits();
  }, [callStatus]);

  useEffect(() => {
    if (hasEnoughCredits !== undefined) {
      !hasEnoughCredits ? setShowModal(true) : setShowModal(false);
    }
  }, [hasEnoughCredits, walletBalance]);

  useEffect(() => {
    shortToContinue && disconnectCall();
  }, [shortToContinue]);

  const heartbeatCredits = () => {
    let interval = setInterval(() => {
      getCredits();
    }, WalletHelper().CREDITS_STATUS_INTERVAL);

    if (walletBalance === 0) {
      clearInterval(interval);
    }
  };

  const closeModal = () => {
    setForceBuyCredits(false);
    setShowModal(false);
  };

  const disconnectCall = () => {
    NotificationsToastsService().error({ message: 'You are out of money!' });
    room!.disconnect();
  };

  const displayCreditsModal =
    (showModal && clientsCall && isFollower(clientsCall.client.clientType)) || forceBuyCredits;

  return { walletBalance, closeModal, displayCreditsModal, setForceBuyCredits, hasEnoughCredits };
};

export default useCreditsHandler;
