import React from 'react';
import ClientConsts from './clientConstants';

const DefaultCurrency = () => {
  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: ClientConsts.DEFAULT_CURRENCY_SIGN }} />
    </>
  );
};

export default DefaultCurrency;
