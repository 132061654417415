import { useAppState } from '../../state';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useClientsCallState } from '../ClientsCalls/useClientsCall/ClientsCallsContext';

export default function useJoinRoom() {
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, localTracks } = useVideoContext();
  const { clientsCall, authToken } = useClientsCallState();

  const join = () => {
    if (!clientsCall) {
      throw Error('ClientsCall undefined');
    }
    const { callId } = clientsCall;
    const { username } = clientsCall.client;
    getToken(username, callId, authToken).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  return { join, isFetching, isAcquiringLocalTracks, isConnecting, localTracks };
}
