import React, { useState } from 'react';
import CreditsPackagesWrapper from '../CreditsPackagesWrapper/CreditsPackagesWrapper';
import SingleCreditButton from '../SingleCreditButton/SingleCreditButton';
import { CreditProps } from '../CreditInterfaces/CreditInterfaces';
import CustomCreditInput from '../CustomCreditInput/CustomCreditInput';
import { MIN_AMOUNT } from '../Payment/PaymentConstants';
import clsx from 'clsx';
import styles from './CreditPackages.module.css';

interface CreditPackagesProps {
  products: any;
  clearSelected: () => void;
  onSelect: (credit: CreditProps) => void;
  setCustomCredit: (value: number) => void;
  message: string | undefined;
}

const CreditPackages = ({ products, clearSelected, onSelect, setCustomCredit, message }: CreditPackagesProps) => {
  const [creditsList, setCreditsList] = useState(products);
  const [showInput, setShowInput] = useState(false);

  const handleSelect = (credit: CreditProps) => {
    setShowInput(false);
    let newCreditsList: CreditProps[] = [];

    creditsList.map((currentCredit: CreditProps) => {
      currentCredit.selected = currentCredit.productId === credit.productId;
      newCreditsList.push(currentCredit);
      return currentCredit;
    });

    setCreditsList(newCreditsList);
    onSelect && onSelect(credit);
  };

  const customCreditHandler = (value: number) => {
    clearSelected();
    setCustomCredit(value);
  };

  const showCustomInput = () => {
    customCreditHandler(MIN_AMOUNT);
    setShowInput(true);
  };

  return (
    <CreditsPackagesWrapper message={message}>
      {creditsList.map((credit: CreditProps) => (
        <SingleCreditButton key={credit.productId} credit={credit} handleSelect={handleSelect} />
      ))}

      {/*TODO - if needed disableCustomCredit should be added as an condition */}
      {!showInput ? (
        <>
          <div
            data-track="video-click-btn-credit-modal-package-price-custom-payment-flow"
            className={clsx(styles.credit_button, styles.long_press, styles.custom_credit_button)}
            onClick={showCustomInput}
          >
            Enter custom amount
          </div>
        </>
      ) : (
        <CustomCreditInput onChange={customCreditHandler} />
      )}
    </CreditsPackagesWrapper>
  );
};

export default CreditPackages;
