import { MIN_AMOUNT, MAX_AMOUNT } from './PaymentConstants';

const PaymentService = () => {
  const isValid = (amount: number) => {
    return typeof amount === 'number' && amount >= MIN_AMOUNT && amount <= MAX_AMOUNT;
  };

  return {
    isValid: isValid,
  };
};

export default PaymentService;
