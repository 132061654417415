import { IMedia } from '../ClientsCalls/useClientsCall/Client';

export const SIZE_AVATAR = 200;

const getResized = (media: IMedia, size: number, shape: string | boolean) => {
  let name = media.mediaPath.substr(0, media.mediaPath.lastIndexOf('.'));
  let extension = media.mediaPath.substr(media.mediaPath.lastIndexOf('.') + 0).toLowerCase();
  if (extension !== '.png' && extension !== '.jpeg' && extension !== '.jpg') {
    // @TODO take attention - if bug is introduced
    // size = false;
    shape = false;
    extension = '.jpeg';
  }
  const sizeValue = size ? `_${size}` : '';
  const shapeValue = shape ? `-${shape}` : '';
  return `${name}${shapeValue}${sizeValue}${extension}`;
};

const Media = (media: IMedia) => {
  return {
    getAvatar: () => getResized(media, SIZE_AVATAR, 'crp'),
    getResized: (size: number, shape: string | boolean) => getResized(media, size, shape),
    isValid: () => media && typeof media === 'object' && media.mediaPath,
  };
};

export default Media;
