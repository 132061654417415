import React from 'react';
import Button from '@material-ui/core/Button';
import WhiteMicIcon from '../../../icons/WhiteMicIcon';
import WhiteMicOffIcon from '../../../icons/WhiteMicOffIcon';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  mobileButtonBg: {
    display: 'flex',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '40%',
    background: 'rgba(0, 0, 0, 0.4)',
    marginLeft: '5px',
    marginRight: '5px',
  },
  startIcon: {
    marginLeft: '0',
    marginRight: '0',
  },
}));

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string; dataTrack?: string }) {
  const classes = useStyles();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <div className={classes.mobileButtonBg}>
      <Button
        className={props.className}
        classes={{ startIcon: classes.startIcon }}
        onClick={toggleAudioEnabled}
        disabled={!hasAudioTrack || props.disabled}
        startIcon={isAudioEnabled ? <WhiteMicIcon /> : <WhiteMicOffIcon />}
        data-cy-audio-toggle
        data-track={props.dataTrack}
      ></Button>
    </div>
  );
}
