import * as React from 'react';
import { SVGProps } from 'react';

function ToastErrorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 40 40" {...props}>
      <defs>
        <style>{'.ToastErrorIcon_svg__b{fill:#fff}'}</style>
      </defs>
      <g transform="translate(-40 -30)">
        <circle cx={20} cy={20} r={20} transform="translate(40 30)" fill="#ff295c" />
        <rect className="ToastErrorIcon_svg__b" width={18} height={4} rx={2} transform="rotate(-45 92.442 -35.563)" />
        <rect className="ToastErrorIcon_svg__b" width={18} height={4} rx={2} transform="rotate(45 -23.441 87.562)" />
      </g>
    </svg>
  );
}

export default ToastErrorIcon;
