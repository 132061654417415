import React from 'react';

import Button from '@material-ui/core/Button';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import WhiteFlipCameraIcon from '../../../icons/WhiteFlipCameraIcon';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  mobileButtonBg: {
    display: 'flex',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '40%',
    background: 'rgba(0, 0, 0, 0.4)',
    marginLeft: '5px',
    marginRight: '5px',
  },
  startIcon: {
    marginLeft: '0',
    marginRight: '0',
  },
  startIconDisabled: {
    marginLeft: '0',
    marginRight: '0',
    opacity: '0.5',
  },
}));

export default function FlipCameraButton(props: { className?: string; dataTrack?: string }) {
  const classes = useStyles();
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  if (!flipCameraSupported) {
    return (
      <div className={classes.mobileButtonBg}>
        <Button
          className={props.className}
          classes={{ startIcon: classes.startIconDisabled }}
          disabled={true}
          startIcon={<WhiteFlipCameraIcon />}
          data-cy-flip-camera-toggle-disabled
          data-track={props.dataTrack}
        ></Button>
      </div>
    );
  }

  return (
    <div className={classes.mobileButtonBg}>
      <Button
        className={props.className}
        classes={{ startIcon: classes.startIcon }}
        onClick={toggleFacingMode}
        disabled={flipCameraDisabled}
        startIcon={<WhiteFlipCameraIcon />}
        data-cy-flip-camera-toggle
      ></Button>
    </div>
  );
}
