import React from 'react';

function WhiteDisconnectIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 45.256 45.255">
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="6"
        data-name="Group 295"
        transform="rotate(45 1364.726 90.115)"
      >
        <path d="M341.999 991.4h52" data-name="Path 649"></path>
        <path d="M367.999 965.4v52" data-name="Path 650"></path>
      </g>
    </svg>
  );
}

export default WhiteDisconnectIcon;
