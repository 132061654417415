import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import DefaultCurrency from '../../../helpers/DefaultCurrency';
import { isPublisher, isFollower } from '../../../helpers/clientHelpers';
import { IClientsCall } from '../../ClientsCalls/useClientsCall/ClientsCallsContext';

const useStyles = makeStyles((theme: Theme) => ({
  corespondentData: {
    color: '#fff',
    fontSize: '16px',
    textAlign: 'center',
  },
}));

interface IProps {
  clientsCall: IClientsCall;
}

const CallPriceAndLocation = ({ clientsCall }: IProps) => {
  const classes = useStyles();

  const displayVideoPrice = clientsCall && isPublisher(clientsCall.corespondentClient.clientType);
  const fanLocation = clientsCall && clientsCall.corespondentClient.locationName;

  return (
    <div className={classes.corespondentData}>
      {displayVideoPrice ? (
        <span>
          Call: {clientsCall.callPrice} <DefaultCurrency />
          /min
        </span>
      ) : null}
      {fanLocation ? (
        <div>
          <span>{fanLocation}</span>
        </div>
      ) : null}
    </div>
  );
};

export default CallPriceAndLocation;
