import React, { useEffect, useState } from 'react';
import { useClientsCallState } from '../ClientsCalls/useClientsCall/ClientsCallsContext';
import { makeStyles, Theme } from '@material-ui/core';

const SECONDS_DIVIDER = 1000;
const MINUTES_DIVIDER = 60000;
const HOURS_DIVIDER = 3600000;
const CALL_STARTED_STATUS = 2;

const useStyles = makeStyles((theme: Theme) => ({
  stopwatch: {
    color: '#fff',
    fontSize: '16px',
    textAlign: 'center',
  },
}));

const CallTimer = () => {
  const classes = useStyles();
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);
  const { callStatus } = useClientsCallState();

  let interval: any;

  useEffect(() => {
    if (callStatus === CALL_STARTED_STATUS) {
      setRunning(true);
    }
  }, [callStatus]);

  useEffect(() => {
    running ? startTimer() : clearInterval(interval);
  }, [running]);

  const startTimer = () => {
    interval = setInterval(() => {
      setTime(prevTime => prevTime + 10);
    }, 10);

    return () => clearInterval(interval);
  };

  return (
    <div className={classes.stopwatch}>
      <span>{('0' + Math.floor((time / HOURS_DIVIDER) % 24)).slice(-2)}:</span>
      <span>{('0' + Math.floor((time / MINUTES_DIVIDER) % 60)).slice(-2)}:</span>
      <span>{('0' + Math.floor((time / SECONDS_DIVIDER) % 60)).slice(-2)}</span>
    </div>
  );
};

export default CallTimer;
