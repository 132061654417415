import React from 'react';

function WhiteMicOffIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 54.479 54.479">
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        data-name="Icon feather-mic-off"
        transform="translate(2.743 2.741)"
      >
        <path d="M1.5 1.502l45.993 45.993" data-name="Path 662"></path>
        <path
          d="M18.225 18.227V24.5a6.272 6.272 0 0010.7 4.432m1.84-9.993V7.774A6.272 6.272 0 0018.35 6.519"
          data-name="Path 663"
        ></path>
        <path
          d="M34.949 34.847A14.634 14.634 0 019.862 24.498v-4.181m29.268 0v4.181a14.634 14.634 0 01-.23 2.571"
          data-name="Path 664"
        ></path>
        <path d="M24.497 39.133v8.362" data-name="Path 665"></path>
        <path d="M16.134 47.495h16.725" data-name="Path 666"></path>
      </g>
    </svg>
  );
}

export default WhiteMicOffIcon;
