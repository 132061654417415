import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { Grid } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import FlipCameraButton from '../Buttons/FlipCameraButton/FlipCameraButton';
import TipButton from '../Buttons/TipButton/TipButton';
import Tips from '../Tips/Tips';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      bottom: '15px',
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,

      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    menuButtonsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      justifyItems: 'center',

      width: '100%',
      maxWidth: '450px',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  const [showTips, setShowTips] = useState<boolean>(false);

  return (
    <>
      <footer className={classes.container}>
        <Grid container className={classes.menuButtonsContainer}>
          <Grid item>
            <ToggleAudioButton disabled={isReconnecting} />
          </Grid>
          <Grid item>
            <ToggleVideoButton disabled={isReconnecting} />
          </Grid>
          <Grid item>
            <FlipCameraButton />
          </Grid>
          <Grid item>
            <TipButton onClick={() => setShowTips(true)} />
            {showTips && <Tips isVisible={setShowTips} />}
          </Grid>
          <Grid item>
            <EndCallButton />
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
