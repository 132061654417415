import React from 'react';

function WhiteMicIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="23" viewBox="0 0 35.273 52">
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        data-name="Icon feather-mic"
        transform="translate(-4.5 1.5)"
      >
        <path
          d="M22.137 1.5a6.273 6.273 0 00-6.273 6.273V24.5a6.273 6.273 0 1012.545 0V7.773A6.273 6.273 0 0022.137 1.5z"
          data-name="Path 654"
        ></path>
        <path d="M36.773 20.318V24.5a14.636 14.636 0 11-29.273 0v-4.182" data-name="Path 655"></path>
        <path d="M22.136 39.136V47.5" data-name="Path 656"></path>
        <path d="M13.773 47.5H30.5" data-name="Path 657"></path>
      </g>
    </svg>
  );
}

export default WhiteMicIcon;
