import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { IClient } from '../../ClientsCalls/useClientsCall/Client';
import Config from '../../../config';
import Media from '../../Media/Media';
import avatar from '../../../images/avatar.png';
import VerifiedBadgeIcon from '../../../icons/VerifiedBadgeIcon';

const useStyles = makeStyles((theme: Theme) => ({
  tipsModelInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '40px',
    height: '40px',
    width: 'fit-content',
    margin: '0 auto 40px auto',
    paddingRight: '20px',
    backgroundColor: theme.colors.searchFormBg,
  },
  corespondentAvatar: {
    display: 'block',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  corespondentUsername: {
    fontSize: '16px',
    fontWeight: 500,
  },
  corespondentVerified: {
    marginLeft: '5px',
  },
}));

interface Props {
  tipReceiver: IClient | undefined;
}

const TipsModelInfo = ({ tipReceiver }: Props) => {
  const classes = useStyles();

  if (!tipReceiver) {
    return <></>;
  }

  const mediaUrl = Media(tipReceiver.media).isValid()
    ? `${Config.STATIC_URL}/${Media(tipReceiver.media).getAvatar()}`
    : avatar;

  return (
    <div className={classes.tipsModelInfoWrapper}>
      <img className={classes.corespondentAvatar} src={mediaUrl} alt="avatar-img" />
      <span className={classes.corespondentUsername}>{tipReceiver.username}</span>
      <span className={classes.corespondentVerified}>{tipReceiver.verified && <VerifiedBadgeIcon />}</span>
    </div>
  );
};

export default TipsModelInfo;
