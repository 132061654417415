import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import WhiteVideoOnIcon from '../../../icons/WhiteVideoOnIcon';
import WhiteVideoOffIcon from '../../../icons/WhiteVideoOffIcon';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  mobileButtonBg: {
    display: 'flex',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '40%',
    background: 'rgba(0, 0, 0, 0.4)',
    marginLeft: '5px',
    marginRight: '5px',
  },
  startIcon: {
    marginLeft: '0',
    marginRight: '0',
  },
}));

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string; dataTrack?: string }) {
  const classes = useStyles();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <div className={classes.mobileButtonBg}>
      <Button
        className={props.className}
        classes={{ startIcon: classes.startIcon }}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
        startIcon={isVideoEnabled ? <WhiteVideoOnIcon /> : <WhiteVideoOffIcon />}
        data-track={props.dataTrack}
      ></Button>
    </div>
  );
}
