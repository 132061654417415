import React from 'react';
import clsx from 'clsx';
import styles from './CreditsPackagesWrapper.module.css';

interface CreditsPackagesWrapperProps {
  children: React.ReactNode;
  message: string | undefined;
}

const CreditsPackagesWrapper = ({ children, message }: CreditsPackagesWrapperProps) => {
  return (
    <div data-track="video-credit-modal-packages-payment-flow" className={styles.credit_packages_wrapper}>
      {message && <div className={clsx(styles.credits_expire_message, styles.center_flex)}>{message}</div>}
      {children}
    </div>
  );
};

export default CreditsPackagesWrapper;
