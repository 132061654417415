import {
  ERROR_STATUS,
  SUCCESS_STATUS,
  WARNING_STATUS,
} from '../../components/Notifications/NotificationToast/NotificationsToast';

const { Subject } = require('rxjs');

const subject = new Subject();

export const NotificationsToastsService = () => {
  const emit = (data: any) => {
    subject.next(data);
  };

  const error = (data: any) => {
    emit({
      ...{ status: ERROR_STATUS },
      ...data,
    });
  };

  const on = (callback: any) => {
    return subject.asObservable().subscribe(callback);
  };

  const success = (data: any) => {
    emit({
      ...{ status: SUCCESS_STATUS },
      ...data,
    });
  };

  const warning = (data: any) => {
    emit({
      ...{ status: WARNING_STATUS },
      ...data,
    });
  };

  return {
    error,
    on,
    success,
    warning,
  };
};
