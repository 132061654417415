import React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/styles';

const CustomButton = styled(Button)({
  borderRadius: '25px',
  backgroundColor: '#3022b3',
  color: '#fff',
  height: '50px',
  fontSize: '20px',
});

interface Props {
  buttonText: string;
  onSubmit: () => void;
  isDisabled: boolean;
}

export const ConfirmButton = ({ buttonText, onSubmit, isDisabled }: Props) => {
  return (
    <CustomButton variant="contained" onClick={onSubmit} fullWidth={true} disabled={isDisabled}>
      {buttonText}
    </CustomButton>
  );
};
