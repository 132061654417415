import React from 'react';
import Success from '../../../icons/ToastSuccessIcon';
import Error from '../../../icons/ToastErrorIcon';
import Info from '../../../icons/ToastInfoIcon';
import Warning from '../../../icons/ToastWarningIcon';
import './NotificationAlert.scss';
import { NotificationType } from '../NotificationToast/NotificationsToast';

interface Props {
  notification: NotificationType;
}

const NotificationAlert = ({ notification }: Props) => {
  let selectedNotification;

  const { status: notificationType, message } = notification;

  switch (notificationType) {
    case 'success':
      selectedNotification = {
        component: <Success />,
        style: 'toast_success',
      };
      break;
    case 'error':
      selectedNotification = {
        component: <Error />,
        style: 'toast_error',
      };
      break;
    case 'info':
      selectedNotification = {
        component: <Info />,
        style: 'toast_info',
      };
      break;
    case 'warning':
      selectedNotification = {
        component: <Warning />,
        style: 'toast_warning',
      };
      break;
    default:
      selectedNotification = {
        component: <Success />,
        style: 'toast_success',
      };
      break;
  }

  return (
    <div className="notification_alert_wrapper">
      <div className={selectedNotification.style}>
        <div className="toast_icon">{selectedNotification.component}</div>
        <div className="toast_text">{message}</div>
      </div>
    </div>
  );
};

export default NotificationAlert;
