//import config from "../../config";

const productsData = [
  {
    productId: 'f311b37d-00ad-45d5-a2d3-c96987c89490',
    price: 20,
    credits: 200,
    // currency: config.DEFAULT_CURRENCY,
    selected: false,
  },
  {
    productId: '6b5cb6b6-1333-4601-8332-acc1705bd1b8',
    price: 50,
    credits: 500,
    // currency: config.DEFAULT_CURRENCY,
    selected: false,
  },
  {
    productId: 'ecaf7ec8-85bb-4af7-9625-9c2a6e7d96bf',
    price: 100,
    credits: 1000,
    // currency: config.DEFAULT_CURRENCY,
    selected: false,
  },
];

const findAll = () => {
  return productsData;
};

const findSelected = () => {
  const selected = findAll().find(credit => credit.selected);
  return selected;
};

export default () => {
  return {
    findAll,
    findSelected,
  };
};
