const WalletHelper = () => {
  const AMOUNT_DIVIDER = 10;
  const CALL_MINUTES_LEFT = 2;
  const CREDITS_STATUS_INTERVAL = 15000;

  const balanceToFloat = (amount: string) => {
    return parseFloat(amount.replace(/,/g, ''));
  };

  const creditsToAmount = (credits: number) => {
    return credits / AMOUNT_DIVIDER;
  };

  const checkCallCreditStatus = (balance: number, callPrice: number) => {
    return balance > callPrice * CALL_MINUTES_LEFT;
  };

  const hasLessThanCallPrice = (balance: number, callPrice: number) => {
    return callPrice / 2 > balance;
  };

  return {
    balanceToFloat,
    creditsToAmount,
    checkCallCreditStatus,
    CREDITS_STATUS_INTERVAL,
    hasLessThanCallPrice,
  };
};

export default WalletHelper;
