import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core';
import WhiteTipIcon from '../../../icons/WhiteTipIcon';
import { useClientsCallState } from '../../ClientsCalls/useClientsCall/ClientsCallsContext';
import { isFollower } from '../../../helpers/clientHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  mobileButtonBg: {
    display: 'flex',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '40%',
    background: 'rgba(0, 0, 0, 0.4)',
    marginLeft: '5px',
    marginRight: '5px',
  },
  startIcon: {
    marginLeft: '0',
    marginRight: '0',
  },
}));

interface Props {
  onClick: () => void;
}

const TipButton = ({ onClick }: Props) => {
  const classes = useStyles();
  const { clientsCall } = useClientsCallState();

  const displayTipButton = clientsCall && isFollower(clientsCall.client.clientType);

  if (!displayTipButton) {
    return null;
  }

  return (
    <div className={classes.mobileButtonBg}>
      <Button
        // className={props.className}
        classes={{ startIcon: classes.startIcon }}
        onClick={onClick}
        // disabled={}
        startIcon={<WhiteTipIcon />}
        data-cy-tip
      ></Button>
    </div>
  );
};

export default TipButton;
