import useLocalAudioToggle from '../useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../useLocalVideoToggle/useLocalVideoToggle';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

let alreadySetAudio = false;
let alreadySetVideo = false;

export function useDisableLocalAudioTrack() {
  const [isAudioTrackEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  const { search } = useLocation();

  const isAudioDisabled = new URLSearchParams(search).get('audio') === 'false';

  useEffect(() => {
    if (isAudioTrackEnabled && isAudioDisabled && !alreadySetAudio) {
      toggleAudioEnabled();
      alreadySetAudio = true;
    }
  }, [isAudioTrackEnabled, isAudioDisabled, toggleAudioEnabled]);
}

export function useDisableLocalVideoTrack() {
  const [isVideoTrackEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  const { search } = useLocation();

  const isVideoDisabled = new URLSearchParams(search).get('video') === 'false';

  useEffect(() => {
    if (isVideoTrackEnabled && isVideoDisabled && !alreadySetVideo) {
      toggleVideoEnabled();
      alreadySetVideo = true;
    }
  }, [isVideoTrackEnabled, isVideoDisabled, toggleVideoEnabled]);
}

export default function useDisableTracksFromParams() {
  useDisableLocalAudioTrack();
  useDisableLocalVideoTrack();
}
