import React, { useState } from 'react';
import { MIN_AMOUNT, MAX_AMOUNT } from '../Payment/PaymentConstants';
import DefaultCurrency from '../../../helpers/DefaultCurrency';
import { ReactComponent as CoinSmall } from './../../../svg/coin_small.svg';
import styles from './CustomCreditInput.module.css';
import { NotificationsToastsService } from '../../../services/notifications/NotificationsToastService';
import clsx from 'clsx';

const MAX_INPUT_LENGTH = 4;

interface CustomCreditInputProps {
  onChange: (value: number) => void;
}

const CustomCreditInput = ({ onChange }: CustomCreditInputProps) => {
  const [customPrice, setCustomPrice] = useState(MIN_AMOUNT);

  const setAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= MAX_INPUT_LENGTH) {
      const value = parseInt(event.target.value);
      setCustomPrice(value);

      if (value < MIN_AMOUNT) {
        NotificationsToastsService().warning({
          message: 'The minimum amount is 15 euros',
        });
      }
      if (value > MAX_AMOUNT) {
        NotificationsToastsService().warning({
          message: 'The maximum amount is 250 euros',
        });
      }

      onChange(value);
    }
  };

  return (
    <>
      <div className={styles.custom_credit_field}>
        <div className={clsx(styles.credit_bold, styles.center_flex)}>
          <CoinSmall className={styles.small_coins} />
        </div>

        <div className="custom_input_wrapper">
          <span>
            <DefaultCurrency />
          </span>
          <input
            className={styles.custom_credit}
            onChange={setAmount}
            type="number"
            placeholder="0"
            value={customPrice}
            autoFocus
          />
        </div>
      </div>
    </>
  );
};

export default CustomCreditInput;
