import * as React from 'react';
import { SVGProps } from 'react';

function ToastInfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 40 40" {...props}>
      <defs>
        <style>{'.ToastInfoIcon_svg__b{fill:#fff}'}</style>
      </defs>
      <g transform="translate(-40 -30)">
        <circle cx={20} cy={20} r={20} transform="translate(40 30)" fill="#3022b3" />
        <rect className="ToastInfoIcon_svg__b" width={14} height={4} rx={2} transform="rotate(90 8 54)" />
        <rect className="ToastInfoIcon_svg__b" width={4} height={4} rx={2} transform="rotate(90 11 51)" />
      </g>
    </svg>
  );
}

export default ToastInfoIcon;
