import React from 'react';
import { ReactComponent as CoinSmall } from './../../../svg/coin_small.svg';
import { ReactComponent as CoinMedium } from './../../../svg/coin_medium.svg';
import { ReactComponent as CoinLarge } from './../../../svg/coin_large.svg';
import DefaultCurrency from '../../../helpers/DefaultCurrency';
import clsx from 'clsx';
import { CreditProps } from '../CreditInterfaces/CreditInterfaces';
import styles from './SingleCreditButton.module.css';

interface SingleCreditButtonProps {
  credit: CreditProps;
  handleSelect: (credit: CreditProps) => void;
}

const SingleCreditButton = ({ credit, handleSelect }: SingleCreditButtonProps) => {
  const srcImage = (credit: number) => {
    switch (credit) {
      case 200:
        return <CoinSmall className={styles.small_coins} />;
      case 500:
        return <CoinMedium className={styles.small_coins} />;
      case 1000:
        return <CoinLarge className={styles.small_coins} />;
      default:
        return <CoinSmall />;
    }
  };

  return (
    <div>
      <div
        data-track={`video-credit-modal-packages-price-${credit.price}`}
        className={clsx(styles.credit_button, styles.long_press, `${credit.selected ? styles.active : ''}`)}
        onClick={() => handleSelect(credit)}
      >
        <div
          data-track={`video-click-btn-credit-modal-package-price-${credit.price}-payment-flow`}
          className={clsx(styles.credit_bold, styles.credits_coins)}
        >
          {srcImage(credit.credits)}
          <span className="credit_ammount">
            <span className={styles.credit_regular}>
              Add{' '}
              <span className={styles.credit_bold}>
                {credit.price} <DefaultCurrency />
              </span>{' '}
              to your wallet
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SingleCreditButton;
