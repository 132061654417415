import React from 'react';

function WhiteTipIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="27" viewBox="0 0 58.981 54.67">
      <g fill="none" stroke="#fff" data-name="Group 283" transform="translate(3 -2)">
        <path
          strokeWidth="6"
          d="M46.047 50.561H6.932a2.173 2.173 0 01-2.3-2.016V26.374h43.716v22.171a2.173 2.173 0 01-2.301 2.016z"
          data-name="Path 556"
        ></path>
        <path
          strokeWidth="6"
          d="M50.781 27.81H2.2A2 2 0 010 26.104v-8.229a2.094 2.094 0 012.3-1.786h48.377a2.1 2.1 0 012.3 1.786v8.229a2 2 0 01-2.196 1.706z"
          data-name="Path 557"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="6.001"
          d="M31.375 7.372l-5.54 8.689 11.52.002a5.854 5.854 0 003.777-8.583l-.01-.022a5.657 5.657 0 00-9.747-.085z"
          data-name="Path 559"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="6.001"
          d="M20.3 7.376l5.538 8.683-11.52.002a5.854 5.854 0 01-3.776-8.583l.01-.021a5.657 5.657 0 019.748-.081z"
          data-name="Path 645"
        ></path>
        <path
          strokeLinejoin="round"
          strokeWidth="6"
          d="M0.066 0L0 35.183"
          data-name="Line 11"
          transform="translate(26.49 15.377)"
        ></path>
      </g>
    </svg>
  );
}

export default WhiteTipIcon;
