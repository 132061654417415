import React from 'react';
import { makeStyles, Grid, Button, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import FlipCameraButton from '../../Buttons/FlipCameraButton/FlipCameraButton';
import CorespondentClientInfo from '../CorespondentAvatar/CorespondentClientInfo';
import { useClientsCallState } from '../../ClientsCalls/useClientsCall/ClientsCallsContext';
import CallPriceAndLocation from '../CallPriceAndLocation/CallPriceAndLocation';
import useJoinRoom from '../../Room/useJoinRoom';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  deviceInfoContainer: {
    height: '100%',
    position: 'fixed',
  },
  controls: {
    width: '100%',
    maxWidth: '450px',
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignEnd: {
    alignSelf: 'end',
    paddingBottom: '30px',

    display: 'flex',
    justifyContent: 'center',
  },
  iconBg: {
    backgroundColor: 'black',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column-reverse',
    width: '100%',
    '& button': {
      margin: '5px 15px',
    },
  },

  joinButton: {
    backgroundColor: '#3022B3',
    fontSize: '20px',
    height: '50px',
    borderRadius: '25px',
  },
  cancelButton: {
    background: 'rgba(0, 0, 0, 0.5)',
    fontSize: '20px',
    height: '50px',
    borderRadius: '25px',
  },

  mobileButtonBar: {
    marginBottom: '45px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  mobileButton: {},
}));

export default function DeviceSelectionScreen() {
  const classes = useStyles();
  const { clientsCall, sendCallInvite, isInviter } = useClientsCallState();
  const { join, isFetching, isAcquiringLocalTracks, isConnecting, localTracks } = useJoinRoom();
  const [audioEnabled] = useLocalAudioToggle();
  const [videoEnabled] = useLocalVideoToggle();
  const env = process.env;

  const dataTrackAudio = audioEnabled
    ? 'click-video-call-modal-turn-off-audio-video-call-flow'
    : 'click-video-call-modal-turn-on-audio-video-call-flow';
  const dataTrackVideo = videoEnabled
    ? 'click-video-call-modal-turn-off-video-video-call-flow'
    : 'click-video-call-modal-turn-on-video-video-call-flow';

  const handleJoin = () => {
    join();
    isInviter && sendCallInvite(); // only for inviter (fan)
  };

  const handleCancel = () => {
    if (clientsCall) {
      window.location.href = `${env.REACT_APP_WEBSITE_URL}/messages/${clientsCall.corespondentClient.clientId}`;
    }
  };

  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  if (!clientsCall) {
    return null;
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={clientsCall.client.username} />

            <Grid container justifyContent="center" className={classes.deviceInfoContainer}>
              <Grid item md={12} sm={12} xs={12} className={classes.alignCenter}>
                <CorespondentClientInfo corespondentClient={clientsCall.corespondentClient} />
                <CallPriceAndLocation clientsCall={clientsCall} />
              </Grid>

              <Grid container justifyContent="center" style={{ height: '50px' }}>
                <Grid item>{isFetching || (isConnecting && <CircularProgress variant="indeterminate" />)}</Grid>
              </Grid>

              <Grid item md={12} sm={12} xs={12} className={classes.alignEnd}>
                <div className={classes.controls}>
                  <div className={classes.mobileButtonBar}>
                    <ToggleAudioButton
                      className={classes.mobileButton}
                      disabled={disableButtons}
                      dataTrack={dataTrackAudio}
                    />
                    <ToggleVideoButton
                      className={classes.mobileButton}
                      disabled={disableButtons}
                      dataTrack={dataTrackVideo}
                    />
                    <FlipCameraButton
                      className={classes.mobileButton}
                      dataTrack="click-video-call-modal-change-camera-video-call-flow"
                    />
                  </div>

                  <div className={classes.joinButtons}>
                    <Button
                      variant="contained"
                      color="primary"
                      data-cy-join-now
                      onClick={handleJoin}
                      disabled={disableButtons}
                      className={classes.joinButton}
                      data-track="click-video-call-modal-start-call-video-call-flow"
                    >
                      {isInviter ? 'Make a Call' : 'Join Call'}
                    </Button>
                  </div>
                  <div className={classes.joinButtons}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.cancelButton}
                      onClick={handleCancel}
                      data-track="click-video-call-modal-cancel-call-video-call-flow"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
