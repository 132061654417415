import React from 'react';

function VerifiedBadgeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" data-name="Verified badge" viewBox="0 0 32 29.018">
      <path
        fill="#0097ed"
        d="M545.668 163.193l2.992-1.079a1.973 1.973 0 012.284.7l1.748 2.539a1.919 1.919 0 001.1.759l3.088.792a1.824 1.824 0 011.411 1.841l-.158 3.028a1.739 1.739 0 00.422 1.229l2.008 2.361a1.747 1.747 0 010 2.275L558.558 180a1.74 1.74 0 00-.422 1.229l.158 3.028a1.823 1.823 0 01-1.411 1.841l-3.088.792a1.916 1.916 0 00-1.1.759l-1.748 2.538a1.971 1.971 0 01-2.284.7l-2.992-1.079a2.007 2.007 0 00-1.362 0l-2.989 1.079a1.97 1.97 0 01-2.284-.7l-1.748-2.538a1.915 1.915 0 00-1.1-.759l-3.089-.792a1.828 1.828 0 01-1.411-1.841l.158-3.028a1.749 1.749 0 00-.42-1.229l-2.008-2.361a1.741 1.741 0 010-2.275l2.001-2.364a1.747 1.747 0 00.42-1.229l-.158-3.028a1.828 1.828 0 011.411-1.841l3.089-.792a1.918 1.918 0 001.1-.759l1.748-2.539a1.972 1.972 0 012.284-.7l2.989 1.079a2.007 2.007 0 001.366.002z"
        data-name="Shape 5 copy 11"
        transform="translate(-528.987 -161.995)"
      ></path>
      <path
        fill="#fff"
        d="M543.722 183a2.1 2.1 0 01-1.372-.506l-4.646-4a1.958 1.958 0 01-.172-2.825 2.109 2.109 0 012.915-.166l3.091 2.663 5.857-6.488a2.112 2.112 0 012.914-.189 1.959 1.959 0 01.195 2.824l-7.228 8.006a2.09 2.09 0 01-1.425.68c-.043-.004-.086.001-.129.001z"
        data-name="Shape 1097"
        transform="translate(-528.987 -161.995)"
      ></path>
    </svg>
  );
}

export default VerifiedBadgeIcon;
