import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import ChatWindow from '../ChatWindow/ChatWindow';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import CorespondentIdentity from '../PreJoinScreens/CorespondentIdentity/CorespondentIdentity';
import { useClientsCallState } from '../ClientsCalls/useClientsCall/ClientsCallsContext';
import CallPriceAndLocation from '../PreJoinScreens/CallPriceAndLocation/CallPriceAndLocation';
import MenuBar from '../MenuBar/MenuBar';
import BuyCredits from '../CreditsFill/BuyCredits/BuyCredits';
import useDisableTracksFromParams from '../../hooks/useDisableTracksFromParams/useDisableTracksFromParams';
import CallTimer from '../CallTimer/CallTimer';
import { CreditsContextProvider } from '../CreditsFill/CreditsContext/CreditsContext';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;

  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px` },
    hideMenu: {
      display: 'none',
    },
  };
});

export default function Room() {
  const classes = useStyles();
  useDisableTracksFromParams();
  const { isChatWindowOpen } = useChatContext();
  const { isBackgroundSelectionOpen } = useVideoContext();
  const { clientsCall } = useClientsCallState();
  const [fullScreen, setFullScreen] = useState(false);

  if (!clientsCall) {
    return null;
  }

  return (
    <CreditsContextProvider>
      <span className={fullScreen ? 'full-screen' : undefined}>
        <div
          className={clsx(classes.container, {
            [classes.rightDrawerOpen]: isChatWindowOpen || isBackgroundSelectionOpen,
          })}
          onClick={() => setFullScreen(!fullScreen)}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 2,
              width: '100%',
              marginTop: '10px',
            }}
          >
            <CorespondentIdentity corespondentClient={clientsCall.corespondentClient} />
            <CallPriceAndLocation clientsCall={clientsCall} />
            <CallTimer />
          </div>

          <MainParticipant />
          <ParticipantList />
          <ChatWindow />
          <BackgroundSelectionDialog />
          <BuyCredits />
        </div>
        {
          <div className={clsx(fullScreen && classes.hideMenu)}>
            <MenuBar />
          </div>
        }
      </span>
    </CreditsContextProvider>
  );
}
