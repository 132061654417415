import React, { createContext, useState } from 'react';
import useCreditsHandler from '../../../hooks/useCreditsHandler/useCreditsHandler';

interface ICreditsContextProvider {
  children?: React.ReactNode;
}

interface ICreditsContext {
  walletBalance: number | undefined;
  closeModal: () => void;
  displayCreditsModal: boolean | null | undefined;
  setForceBuyCredits: (arg: boolean) => void;
  hasEnoughCredits: boolean | undefined;
}

const CreditsContext = createContext<ICreditsContext>({
  walletBalance: undefined,
  closeModal: () => false,
  displayCreditsModal: null,
  setForceBuyCredits: () => false,
  hasEnoughCredits: undefined,
});

export const CreditsContextProvider = ({ children }: ICreditsContextProvider) => {
  const { walletBalance, closeModal, displayCreditsModal, setForceBuyCredits, hasEnoughCredits } = useCreditsHandler();

  return (
    <CreditsContext.Provider
      value={{ walletBalance, closeModal, displayCreditsModal, setForceBuyCredits, hasEnoughCredits }}
    >
      {children}
    </CreditsContext.Provider>
  );
};

export default CreditsContext;
