// TODO move to separate helper file
const toCamel = (str: any) => {
  return str.replace(/([-_][a-z])/gi, ($1: string) => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isObject = function(obj: any) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

// @ts-ignore
const keysToCamel = function(obj: any) {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach(k => {
      // @ts-ignore
      n[toCamel(k)] = keysToCamel(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map(i => {
      return keysToCamel(i);
    });
  }

  return obj;
};

const convertToCamelCase = (response: any) => {
  return keysToCamel(response);
};

const responseDecorator = (response: any) => {
  if ((response.code >= 200 && response.code <= 299) || response.code === 304) {
    response.isSuccess = true;
  } else {
    response.error = {
      code: response.code,
      message: response && response.message ? response.message : 'an error has occurred',
    };
  }
  return response;
};

export default function useFetch(url: string, header: any, coreServer: boolean = false) {
  url = `${process.env.REACT_APP_SERVER_URL}${url}`;

  const get = () => {
    return fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-nd-authentication': header.authToken,
      },
    })
      .then(response => response.json())
      .then(responseDecorator)
      .then(convertToCamelCase);
  };

  const put = (data: any) => {
    return fetch(url, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'x-nd-authentication': header.authToken,
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(responseDecorator)
      .then(convertToCamelCase);
  };

  const post = (data: any) => {
    return fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-nd-authentication': header.authToken,
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(responseDecorator)
      .then(convertToCamelCase);
  };

  return {
    get,
    put,
    post,
  };
}
