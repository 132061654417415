import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ModalPopUp from '../../ModalPopUp/ModalPopUp';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitle: {
    fontSize: '21px',
    color: theme.colors.dark,
    fontWeight: 500,
  },
  modalText: {
    fontSize: '17px',
    color: theme.colors.mediumGrey,
    marginBottom: '10px',
  },
}));

interface Props {
  children: React.ReactNode;
  onClose: (arg: boolean) => void;
}

const TipsModal = ({ onClose, children }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ModalPopUp closeAction={() => onClose(false)}>
      <div>
        <div className={classes.modalTitle}>{t('send a tip')}</div>
        <div className={classes.modalText}>{t('how much would you like to tip')}</div>
        {children}
      </div>
    </ModalPopUp>
  );
};

export default TipsModal;
