import React from 'react';
import TipsModal from './TipsModal/TipsModal';
import TipsModelInfo from './TipsModelInfo/TipsModelInfo';
import TipForm from './TipForm/TipForm';
import { useClientsCallState } from '../ClientsCalls/useClientsCall/ClientsCallsContext';

interface Props {
  isVisible: (arg: boolean) => void;
}

const Tips = ({ isVisible }: Props) => {
  const { clientsCall, authToken } = useClientsCallState();

  return (
    <TipsModal onClose={isVisible}>
      <TipsModelInfo tipReceiver={clientsCall?.corespondentClient} />
      <TipForm
        closeAction={isVisible}
        inviterClientId={clientsCall?.inviterClientId}
        responderClientId={clientsCall?.responderClientId}
        authToken={authToken}
      />
    </TipsModal>
  );
};

export default Tips;
