import { useState, useEffect } from 'react';
import { useClientsCallState } from '../../components/ClientsCalls/useClientsCall/ClientsCallsContext';
import useFetch from '../useFetch/useFetch';

const useCreditsPurchase = (selectedAmount: number | undefined) => {
  const { authToken } = useClientsCallState();
  const [purchaseAmount, setPurchaseAmount] = useState(selectedAmount);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);

  const { post: buyMoreCredits } = useFetch(`/payment`, { authToken });

  useEffect(() => {
    purchaseAmount && makePurchase();
  }, [purchaseAmount]);

  const makePurchase = async () => {
    setLoading(true);

    try {
      const response = await buyMoreCredits({ amount: purchaseAmount });

      if (response.isSuccess) {
        setPurchaseAmount(undefined);
        setIsSuccess(true);
        setLoading(false);
      } else {
        setPurchaseAmount(undefined);
        setIsSuccess(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { setPurchaseAmount, loading, isSuccess, setIsSuccess };
};

export default useCreditsPurchase;
