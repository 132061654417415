import React from 'react';

function WhiteVideoOffIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 68.2 68.2">
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        data-name="Icon feather-video-off"
        transform="translate(2.743 2.743)"
      >
        <path
          d="M42.214 42.214v2.714a5.429 5.429 0 01-5.428 5.429H6.929A5.429 5.429 0 011.5 44.928V17.786a5.429 5.429 0 015.429-5.429h5.429m15.363 0h9.066a5.429 5.429 0 015.429 5.429v9.066l2.714 2.714 16.286-11.78v27.142"
          data-name="Path 660"
        ></path>
        <path d="M1.5 1.5l59.714 59.714" data-name="Path 661"></path>
      </g>
    </svg>
  );
}

export default WhiteVideoOffIcon;
