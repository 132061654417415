import React, { useEffect, useState } from 'react';
import { useClientsCallState } from '../../ClientsCalls/useClientsCall/ClientsCallsContext';
import useJoinRoom from '../../Room/useJoinRoom';

export default function DirectCall() {
  const { sendCallInvite, isInviter } = useClientsCallState();
  const { join, localTracks } = useJoinRoom();
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (!redirecting && localTracks.length > 1) {
      handleJoin();
      setRedirecting(true);
    }
  }, [localTracks]);

  const handleJoin = () => {
    join();
    isInviter && sendCallInvite(); // only for inviter (fan)
  };

  return <>Loading ...</>;
}
