import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  modalPopup: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: 500,
  },
  fillEmpty: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  popupWrapper: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    backgroundColor: theme.colors.light,
    paddingBottom: '15px',
    borderRadius: '15px 15px 0px 0px',
    animation: 'slidein 0.3s ease-in',
    maxWidth: '450px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  popupWrapperOverflow: {
    height: '90%',
    overflow: 'auto',
  },
  contentWrapper: {
    width: '100%',
    padding: '0 20px',
    backgroundColor: theme.colors.light,
    fontSize: '21px',
    marginBottom: '20px',
    textAlign: 'center',
    animation: 'slidein 0.3s ease-in',
  },
  horizontalLine: {
    display: 'block',
    margin: '10px auto 47px auto',
    backgroundColor: theme.colors.lightGrey,
    height: '5px',
    width: '50px',
    borderRadius: '10px',
  },
}));

interface Props {
  children: React.ReactNode;
  closeAction: () => void;
}

const ModalPopUp = ({ children, closeAction }: Props) => {
  const classes = useStyles();

  const popupRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [isOverflown, setIsOverflown] = useState<boolean>();

  useEffect(() => {
    setIsOverflown(popupRef.current.offsetTop < 0);
  }, [children]);

  return (
    <div className={classes.modalPopup}>
      <div className={classes.fillEmpty} onClick={closeAction}></div>
      <div className={`${classes.popupWrapper} ${isOverflown ? `${classes.popupWrapperOverflow}` : ''}`} ref={popupRef}>
        <div className={classes.contentWrapper}>
          <span className={classes.horizontalLine}></span>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalPopUp;
